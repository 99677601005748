import React, { useMemo } from "react"
import { useInputChange } from "../custom-hook/useForm"
import {
  passwordReset,
  facebookLogin,
  googleLogin,
  handleLogin,
  handleSignup,
  signingOut
} from "../helper/firebase/transaction/auth"
import { handleStartKYC, handleKycNextBtn } from "../helper/firebase/transaction/profile.trx"
import "../assets/styles/components/registrationForm.scss"
import { useDispatch, useSelector } from "react-redux"
import { Dispatch } from "@reduxjs/toolkit"
import countryList from "react-select-country-list"
import { generateAccessToken } from "../helper/firebase/transaction/auth"
import { Link } from "gatsby"

const RegistrationForm = () => {
  const [input, handleInputChange] = useInputChange()

  const countryOptions = useMemo(() => countryList().getData(), [])

  const userStatus = useSelector((state: any) => state.auth.userStatus)
  const kycProfileStatus = useSelector((state: any) => state.profileKyc.kycProfileStatus)

  const dispatch = useDispatch()

  const emailLoginCustomHook = handleLogin(dispatch as Dispatch)
  const emailCustomHook = handleSignup(dispatch as Dispatch)
  const passwordResetCustomHook = passwordReset(dispatch as Dispatch)
  const googleCustomHook = googleLogin(dispatch as Dispatch)
  const facebookCustomHook = facebookLogin(dispatch as Dispatch)
  const signOutCustomHook = signingOut(dispatch as Dispatch)
  const kycCustomHook = handleStartKYC(dispatch as Dispatch)
  const nxtKycCustomHook = handleKycNextBtn(dispatch as Dispatch)

  const userLevel = kycProfileStatus?.profile?.level

  return (
    <>
      <div className="auth-form">
        <label htmlFor="email">Email</label>
        <input type="email" required={true} name="email" placeholder="Enter email" onChange={handleInputChange} />
        <label htmlFor="phone-number">Phone Number</label>
        <input type="tel" required name="phoneNumber" placeholder="Enter Phone Number" onChange={handleInputChange} />
        <label htmlFor="password">Password</label>
        <input type="password" required name="password" placeholder="Enter Password" onChange={handleInputChange} />
        <div className="button-container">
          <button
            type="submit"
            className="btn"
            onClick={() => emailCustomHook(input.email, input.phoneNumber, input.password, userStatus)}
          >
            register
          </button>
          <button type="submit" className="btn" onClick={() => googleCustomHook(userStatus, kycProfileStatus)}>
            signin with Google
          </button>
          <button type="submit" className="btn" onClick={() => facebookCustomHook(userStatus, kycProfileStatus)}>
            signin with Facebook
          </button>
          <div>
            <Link
              to={userLevel > 0 ? "/account-details/status" : "/account-details/single-professional-host/your-identity"}
            >
              {" "}
              <button type="submit" className="btn" onClick={() => kycCustomHook(kycProfileStatus, userStatus)}>
                Start KYC
              </button>
            </Link>
          </div>
        </div>
      </div>

      <form className="login-form">
        <label htmlFor="email">Email</label>
        <input type="email" required={true} name="email" placeholder="Enter email" onChange={handleInputChange} />
        <label htmlFor="password">Password</label>
        <input type="password" required name="password" placeholder="Enter Password" onChange={handleInputChange} />

        <button
          type="submit"
          className="btn"
          onClick={() => emailLoginCustomHook(input.email, input.password, userStatus, kycProfileStatus)}
        >
          Login
        </button>
        <button type="submit" className="btn" onClick={() => signOutCustomHook()}>
          Logout
        </button>

        <div>
          <label htmlFor="email">Enter email to reset password</label>
          <input type="email" required name="userEmail" placeholder="Enter email" onChange={handleInputChange} />
          <button type="submit" className="btn" onClick={() => passwordResetCustomHook(input.userEmail)}>
            reset
          </button>
        </div>
      </form>

      <div className="kyc-form">
        <div className={userLevel === 1 ? "active" : "inactive"}>
          <label htmlFor="businessCategory">
            <input type="radio" required name="businessCategory" value="Business" onChange={handleInputChange} />
            Business
          </label>
          <label htmlFor="email">
            <input type="radio" name="businessCategory" value="Individual" onChange={handleInputChange} />
            Individual
          </label>
        </div>
        <div className={userLevel === 2 ? "active" : "inactive"}>
          <label htmlFor="name">Name</label>
          <input type="text" required name="name" placeholder="Enter name" onChange={handleInputChange} />
          <label htmlFor="companyAddress">Company Address</label>
          <input
            type="text"
            required
            name="companyAddress"
            placeholder="Enter Company Address"
            onChange={handleInputChange}
          />
          <label htmlFor="companyAddress">Country</label>
          <select value={input?.country} name="country" onChange={handleInputChange}>
            <option disabled={true} value="" className="default-option">
              select-your-country
            </option>
            {countryOptions.map((country, key) => (
              <option key={key} value={`${country.label}`}>{`${country.label}`}</option>
            ))}
          </select>
        </div>

        <button
          type="submit"
          className={userLevel ? "next-btn active" : "inactive"}
          onClick={() => nxtKycCustomHook(input, kycProfileStatus, userStatus)}
        >
          next
        </button>

        <button type="submit" className="next-btn" onClick={() => generateAccessToken()}>
          generate new token
        </button>
      </div>
    </>
  )
}

export default RegistrationForm
