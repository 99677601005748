import React from "react"
import RegistrationForm from "../components/registrationForm"

const Register = () => {
  return (
    <>
      <RegistrationForm />
    </>
  )
}

export default Register
