import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signOut,
  signInWithPopup,
  FacebookAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail
} from "firebase/auth"
import app from "../index"
import { collection, addDoc, query, getDocs, where, getDoc, doc } from "firebase/firestore"
import { userActions } from "../../duck/auth"
import { db } from "../index"
import { FirebaseError } from "firebase/app"
import { errorFactory, userLogin } from "../../duck/auth/index.td"
import { Dispatch } from "@reduxjs/toolkit"
import { userKycActions } from "../../duck/profile"
import { kycProfile } from "../../duck/profile/index.td"

const auth = getAuth(app)

export const handleLogin =
  (dispatch: Dispatch) =>
  async (email: string, password: string, userStatus: userLogin, kycProfileStatus: kycProfile) => {
    let code: errorFactory = {}
    try {
      const exeptErr = new FirebaseError("firestore/failed-login", `Failed to login user: wrong email or password`)
      code = { code: exeptErr.code, message: exeptErr.message }

      const popup = await signInWithEmailAndPassword(auth, email, password)
      if (popup.user) {
        dispatch(userActions.userLoginSuccess({ ...userStatus, user: popup?.user }))

        const docRef = doc(db, "users-KYC", `${popup.user.uid}`)
        let userKyc
        try {
          const docSnap = await getDoc(docRef)
          userKyc = docSnap.data()
        } catch (error) {
          console.log(error)
        }

        dispatch(userKycActions.kycSuccess({ ...kycProfileStatus, profile: userKyc }))
        return
      }

      dispatch(userActions.userLoginFailed(code))
      throw exeptErr
    } catch (error: errorFactory) {
      code = { code: error?.code }
      if (error instanceof FirebaseError) dispatch(userActions.userLoginFailed(code))
    }
  }

export const handleSignup =
  (dispatch: Dispatch) => async (email: string, phoneNumber: string, password: string, userStatus: userLogin) => {
    dispatch(userActions.userRegistration())
    let code: errorFactory = {}
    try {
      const exeptErr = new FirebaseError(
        "firestore/failed-create-user",
        `Failed to create the user with the email: '${email}' in the database.`
      )
      code = { code: exeptErr?.code, message: exeptErr?.message }
      const popup = await createUserWithEmailAndPassword(auth, email, password)
      if (popup?.user) {
        dispatch(userActions.userRegisterSuccess({ ...userStatus, user: popup?.user }))
        addDoc(collection(db, "users"), {
          uid: popup?.user?.uid,
          phoneNumber,
          authProvider: "local",
          email
        })
        await sendEmailVerification(popup.user)
        return
      }
      dispatch(userActions.userRegisterFailed(code))
      throw exeptErr
    } catch (error: errorFactory) {
      code = { code: error?.code }
      if (error instanceof FirebaseError) dispatch(userActions.userRegisterFailed(code))
    }
  }

export const googleLogin = (dispatch: Dispatch) => async (userStatus: userLogin, kycProfileStatus: kycProfile) => {
  dispatch(userActions.userLogin())
  let code: errorFactory = {}
  const exeptErr = new FirebaseError(
    "firestore/failed-google-login-user",
    `Failed to login the user with the email: '${userStatus.user.email}'.`
  )
  code = { code: exeptErr?.code, message: exeptErr?.message }

  try {
    const provider = new GoogleAuthProvider()
    const popup = await signInWithPopup(auth, provider)
    const trx = query(collection(db, "users"), where("email", "==", popup?.user?.email))
    const querySnapshot = await getDocs(trx)
    let userData = {}
    querySnapshot.forEach(doc => {
      userData = { ...doc.data() }
    })
    dispatch(userActions.userLoginSuccess({ ...userStatus, user: popup.user }))

    if (Object.keys(userData).length === 0) {
      addDoc(collection(db, "users"), {
        uid: popup.user.uid,
        phoneNumber: popup.user.phoneNumber,
        authProvider: popup.user.providerData[0].providerId,
        email: popup.user.email
      })
      return
    }

    const docRef = doc(db, "users-KYC", `${popup.user.uid}`)
    let userKyc
    try {
      const docSnap = await getDoc(docRef)
      userKyc = docSnap.data()
    } catch (error) {
      console.log(error)
    }
    dispatch(userKycActions.kycSuccess({ ...kycProfileStatus, profile: userKyc }))
  } catch (error: errorFactory) {
    code = { code: error?.code }
    if (error instanceof FirebaseError) dispatch(userActions.userLoginFailed(code))
  }
}

export const facebookLogin = (dispatch: Dispatch) => async (userStatus: userLogin, kycProfileStatus: kycProfile) => {
  dispatch(userActions.userLogin())
  let code: errorFactory = {}
  const exeptErr = new FirebaseError(
    "firestore/failed-facebook-login-user",
    `Failed to login the user with the email: '${userStatus.user.email}'`
  )
  code = { code: exeptErr?.code, message: exeptErr?.message }

  try {
    const provider = new FacebookAuthProvider()
    const popup = await signInWithPopup(auth, provider)
    dispatch(userActions.userLoginSuccess({ ...userStatus, user: popup.user }))
    addDoc(collection(db, "users"), {
      uid: popup.user.uid,
      phoneNumber: popup.user.phoneNumber,
      authProvider: popup.user.providerData[0].providerId,
      email: popup.user.email
    })

    const docRef = doc(db, "users-KYC", `${popup.user.uid}`)
    let userKyc
    try {
      const docSnap = await getDoc(docRef)
      userKyc = docSnap.data()
    } catch (error) {
      console.log(error)
    }
    dispatch(userKycActions.kycSuccess({ ...kycProfileStatus, profile: userKyc }))

    return
  } catch (error: errorFactory) {
    code = { code: error?.code }
    if (error instanceof FirebaseError) dispatch(userActions.userLoginFailed(code))
  }
}

export const passwordReset = (dispatch: Dispatch) => async (email: string) => {
  let code: errorFactory = {}
  const exeptErr = new FirebaseError(
    "firestore/failed-reset-password",
    `Failed to reset password for user with the email: '${email}'`
  )
  code = { code: exeptErr?.code, message: exeptErr?.message }
  try {
    const response: void = await sendPasswordResetEmail(auth, email)
    return response
  } catch (error: errorFactory) {
    code = { code: error?.code }
    if (error instanceof FirebaseError) dispatch(userActions.passwordResetFailed(code))
  }
}

export const signingOut = (dispatch: Dispatch) => async () => {
  let code: errorFactory = {}
  const exeptErr = new FirebaseError("firestore/failed-logout-user", `Failed to sign user out'`)
  code = { code: exeptErr?.code, message: exeptErr?.message }
  try {
    const popup: void = await signOut(auth)
    dispatch(userActions.userSignoutSuccess())
    return popup
  } catch (error: errorFactory) {
    code = { code: error?.code }
    if (error instanceof FirebaseError) dispatch(userActions.userSignoutFailed(code))
    throw error
  }
}

export const generateAccessToken = async () => {
  const userToken = await auth.currentUser?.getIdToken()

  console.log(userToken)
}
